import './home.scss';
import '../../assets/scripts/libs.jsx';
import '../../components/navigation/navigation.jsx';
import '../../components/footer/footer.jsx';
import {tns} from '../../node_modules/tiny-slider/dist/tiny-slider';
import AOS from 'aos';

AOS.init();
tns({
    container: '.header-slider',
    items: 1,
    mode: 'gallery',
    speed: 800,
    slideBy: 'page',
    autoplayButtonOutput: false,
    controls: false,
    navPosition: 'bottom',
    autoplay: true
});
